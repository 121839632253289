<template>
  <v-dialog v-model="notificationModal.flag" persistent max-width="340">
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" dark v-bind="attrs" v-on="on"> Open Dialog </v-btn>
    </template>
    <v-card>
      <v-card-title class="text-h5">
        Please wait while report is generating, It may take <br> some time.
      </v-card-title>
      <v-card-text>
        Caution:- Do not perform any action until report is generated.
      </v-card-text>
      <v-card-actions class="justify-center">
        <v-progress-circular
          indeterminate
          color="#38227A"
        ></v-progress-circular>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "NotificationModal",
  props: ["notificationModal"],
};
</script>
